import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import {
  Modal,
  GridContainer,
  GridRow,
  GridColumn,
  Utils,
  Button,
} from "arv-reactcomponents";
import {
  APP_PROMPT_MESSAGE,
  APP_IMAGE,
  APP_PROMPT_OK,
  APP_PROMPT_CANCEL,
  APP_PROMPT_URL,
  APP_BROWSER,
  APP_OS,
  APP_PROMPT_KEY,
  APP_PROMPT_VALUE,
} from "npmlinks-constants";
import { getAppURLPrompt } from "../../utils";

@inject("CommonStore")
@observer
class MobileAppPrompt extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPrompt: false,
    };

    this.handleAppPrompt = this.handleAppPrompt.bind(this);
    this.closePrompt = this.closePrompt.bind(this);
  }

  componentDidMount() {
    const appPromptQurey = Utils.getUrlParameter(
      window.location.search,
      APP_PROMPT_KEY,
    );
    if (appPromptQurey === APP_PROMPT_VALUE) {
      this.handleAppPrompt();
    }
  }

  handleAppPrompt() {
    const { isDesktop } = this.props.CommonStore;

    if (
      !isDesktop &&
      window.navigator.appVersion.indexOf(APP_OS) !== -1 &&
      window.navigator.appVersion.indexOf(APP_BROWSER) !== 1
    ) {
      setTimeout(() => {
        this.setState({ showPrompt: true });
      }, 300);
    }
  }

  closePrompt() {
    console.log("---->closeprompt");
    this.setState({
      showPrompt: false,
    });
  }

  render() {
    const { showPrompt } = this.state;
    return (
      <Modal
        isOpen={showPrompt}
        onClose={this.closePrompt}
        className="nw-mobileappprompt"
      >
        <GridContainer>
          <GridRow>
            <GridColumn className="nwc-grid-col">
              <div className="nw-mobileappprompt-header">
                <img
                  src={APP_IMAGE}
                  alt={APP_IMAGE}
                  className="nw-mobileappprompt-logo"
                />
                <div className="nw-mobileappprompt-title">
                  {" "}
                  {APP_PROMPT_MESSAGE}{" "}
                </div>
              </div>
            </GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn className="nwc-grid-col">
              <div className="nw-mobileappprompt-footer">
                <Button
                  className="nwc-btn-primary-txt nw-mobileappprompt-cancel"
                  id="nw-mobileappprompt-cancel"
                  onClick={this.closePrompt}
                >
                  {APP_PROMPT_CANCEL}
                </Button>
                <a
                  className="nwc-btn-primary-txt nw-mobileappprompt-ok"
                  href={getAppURLPrompt(window.location.href)}
                >
                  {APP_PROMPT_OK}
                </a>
              </div>
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Modal>
    );
  }
}

MobileAppPrompt.wrappedComponent.propTypes = {
  CommonStore: PropTypes.shape({
    isDesktop: PropTypes.bool.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("CommonStore")(observer(component));;

export const ProjectComponent = {
  getFinalComponent,
  wrapper: MobileAppPrompt.wrappedComponent,
  component: MobileAppPrompt,
};

export default ProjectComponent.component;
